import React from 'react'
// Styles
import * as styles from './index.module.css'

type BlockquoteProps = {}

const Blockquote: React.FC<BlockquoteProps> = ({ children }) => {
    return <blockquote className={styles.blockquote}>{children}</blockquote>
}

export default Blockquote
