import React from 'react'
import { Link } from 'gatsby'
// Models
import { IProfileMenu } from 'models/Profile'
// Styles
import * as styles from './ProfileMenu.module.css'

const ProfileMenu: React.FC<IProfileMenu> = ({ menu }) => {
    return (
        <aside className={styles.profile_menu}>
            {menu.map(({ text, to, count }) => (
                <Link
                    key={to}
                    className={styles.profile_menu_item}
                    activeClassName={styles.active}
                    to={to}
                >
                    {text}
                    {typeof count === 'number' && (
                        <span className={styles.count}>{count}</span>
                    )}
                </Link>
            ))}
        </aside>
    )
}

export default ProfileMenu
