// extracted by mini-css-extract-plugin
export var profile_header = "ProfileHeader-module--profile_header--aA3U9";
export var profile_background = "ProfileHeader-module--profile_background--l8thR";
export var profile_avatar_and_info = "ProfileHeader-module--profile_avatar_and_info--BLIAb";
export var profile_badge = "ProfileHeader-module--profile_badge--rj2RY";
export var profile_avatar = "ProfileHeader-module--profile_avatar--Ko1hu";
export var profile_info = "ProfileHeader-module--profile_info--Ujovr";
export var profile_name = "ProfileHeader-module--profile_name--hIEuu";
export var profile_subname = "ProfileHeader-module--profile_subname--y2kFf";
export var profile_details = "ProfileHeader-module--profile_details--BS14j";
export var profile_authors = "ProfileHeader-module--profile_authors--LY88T";
export var social_icons = "ProfileHeader-module--social_icons--GCM6S";
export var icon = "ProfileHeader-module--icon--eng3i";