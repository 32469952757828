import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import ProfileContainer from 'components/Sections/Profile/ProfileContainer'
import PageTitle from 'components/UIElements/PageTitle'
import SongsList from 'components/Sections/Songs/SongsList'
import Blockquote from 'components/UIElements/Blockquote'
import PageNav from 'components/UIElements/Navigation/PageNav'
// Models
import { IProfileHeader, IProfileMenuItem } from 'models/Profile'
import { IContextWithPageNav } from 'models/Context'
import { IOptionalMetaProps } from 'models/SEO'
import { ISongCard } from 'models/Song'
import { IUser } from 'models/User'

type UserTranslationsPageProps = {
    user: IUser
    translations: {
        totalCount: number
        nodes: ISongCard[]
    }
    articles: {
        totalCount: number
    }
}

const UserTranslationsPage: React.FC<
    PageProps<UserTranslationsPageProps, IContextWithPageNav>
> = ({ data, pageContext: context }) => {
    const { currentPage, totalPages } = context
    const user = data.user
    const totalTranslations = data.translations.totalCount
    const totalArticles = data.articles.totalCount
    const seo: IOptionalMetaProps = {
        title: user.name,
        description: `Traducciones aportadas por ${user.name}`,
        image: user.avatar.file?.url,
    }

    const { name, avatar, cover, username } = user
    const { spotify, twitter, youtube, instagram, web } = user

    const headerProps: IProfileHeader = {
        type: 'Usuario',
        name,
        subname: `@${username}`,
        avatar,
        cover,
        social: {
            spotify,
            twitter,
            youtube,
            instagram,
            web,
        },
    }

    const menu: IProfileMenuItem[] = [
        { text: `Perfil`, to: `/users/${username}` },
        {
            text: `Artículos`,
            to: `/users/${username}/articles`,
            count: totalArticles,
        },
        {
            text: `Traducciones`,
            to: `/users/${username}/translations`,
            count: totalTranslations,
        },
    ]

    const translations = data.translations.nodes

    return (
        <Layout seo={seo}>
            <ProfileContainer menu={menu} {...headerProps}>
                <PageTitle title="Traducciones" />
                {translations.length > 0 ? (
                    <SongsList songs={translations} />
                ) : (
                    <Blockquote>No hay nada para mostrar...</Blockquote>
                )}
                <PageNav
                    to={`/users/${user.username}/translations`}
                    totalPages={totalPages}
                    currentPage={currentPage}
                />
            </ProfileContainer>
        </Layout>
    )
}

export default UserTranslationsPage

export const pageQuery = graphql`
    query UserTranslationsPage($username: String!, $limit: Int!, $skip: Int!) {
        user: contentfulUsers(username: { eq: $username }) {
            name
            username
            birthday

            description {
                raw
            }

            avatar {
                gatsbyImageData(width: 200)
                file {
                    url
                }
            }
            cover {
                gatsbyImageData(layout: CONSTRAINED)
            }

            twitter
            instagram
            spotify
            youtube
            web
        }

        translations: allContentfulSongs(
            limit: $limit
            skip: $skip
            sort: { fields: release_date, order: DESC }
            filter: {
                translators: { elemMatch: { username: { eq: $username } } }
            }
        ) {
            totalCount
            nodes {
                name
                slug

                release_date
                duration

                thumbnail {
                    gatsbyImageData(width: 50)
                }
            }
        }

        articles: allContentfulArticles(
            filter: { author: { username: { eq: $username } } }
        ) {
            totalCount
        }
    }
`
