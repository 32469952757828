import React from 'react'
// Components
import ProfileHeader from './ProfileHeader'
import ProfileContent from './ProfileContent'
import ProfileMenu from './ProfileMenu'
// Models
import { IProfileHeader, IProfileMenu } from 'models/Profile'
// Styles
import * as styles from './ProfileContainer.module.css'

const ProfileContainer: React.FC<IProfileHeader & IProfileMenu> = ({
    menu,
    type,
    social,
    name,
    subname,
    avatar,
    cover,
    children,
}) => {
    return (
        <>
            <ProfileHeader
                name={name}
                type={type}
                subname={subname}
                avatar={avatar}
                cover={cover}
                social={social}
            />
            <main className={styles.profile_container}>
                <ProfileMenu menu={menu} />
                <ProfileContent>{children}</ProfileContent>
            </main>
        </>
    )
}

export default ProfileContainer
