import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
// Components
import SocialIcons from 'components/UIElements/SocialIcons'
// Models
import { IProfileHeader } from 'models/Profile'
// Styles
import * as styles from './ProfileHeader.module.css'

const ProfileHeader: React.FC<IProfileHeader> = ({
    type,
    name,
    subname,
    avatar,
    cover,
    social,
}) => {
    return (
        <header className={styles.profile_header}>
            <GatsbyImage
                className={styles.profile_background}
                image={cover.gatsbyImageData}
                alt={name}
                loading="eager"
            />
            <figure className={styles.profile_avatar_and_info}>
                <GatsbyImage
                    className={styles.profile_avatar}
                    image={avatar.gatsbyImageData}
                    alt={name}
                    loading="eager"
                />
                <div className={styles.profile_info}>
                    <span className={styles.profile_badge}>{type}</span>
                    <h1 className={styles.profile_name}>{name}</h1>
                    <p className={styles.profile_subname}>{subname}</p>
                    <SocialIcons className={styles.social_icons} {...social} />
                </div>
            </figure>
        </header>
    )
}

export default ProfileHeader
