import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type SocialIconsProps = {
    className: string
    twitter?: string
    facebook?: string
    instagram?: string
    youtube?: string
    spotify?: string
    youtubeMusic?: string
    tiktok?: string
    web?: string
    amazonMusic?: string
    deezer?: string
}

const SocialIcons: React.FC<SocialIconsProps> = ({
    className,
    youtube,
    youtubeMusic,
    spotify,
    deezer,
    amazonMusic,
    twitter,
    instagram,
    facebook,
    tiktok,
    web,
}) => {
    return (
        <nav className={className}>
            {twitter && (
                <a
                    href={`https://www.twitter.com/${twitter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a Twitter"
                >
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                </a>
            )}
            {facebook && (
                <a
                    href={facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a Facebook"
                >
                    <FontAwesomeIcon icon={['fab', 'facebook']} />
                </a>
            )}
            {instagram && (
                <a
                    href={`https://www.instagram.com/${instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a Instagram"
                >
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                </a>
            )}
            {youtube && (
                <a
                    href={youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a Youtube"
                >
                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                </a>
            )}
            {youtubeMusic && (
                <a
                    href={youtubeMusic}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a Youtube Music"
                >
                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                </a>
            )}
            {spotify && (
                <a
                    href={spotify}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a Spotify"
                >
                    <FontAwesomeIcon icon={['fab', 'spotify']} />
                </a>
            )}
            {deezer && (
                <a
                    href={deezer}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a Deezer"
                >
                    <FontAwesomeIcon icon={['fab', 'deezer']} />
                </a>
            )}
            {amazonMusic && (
                <a
                    href={amazonMusic}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a Amazon Music"
                >
                    <FontAwesomeIcon icon={['fab', 'amazon']} />
                </a>
            )}
            {tiktok && (
                <a
                    href={`https://www.tiktok.com/@${tiktok}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a TikTok"
                >
                    <FontAwesomeIcon icon={['fab', 'tiktok']} />
                </a>
            )}
            {web && (
                <a
                    href={web}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Enlace a Página Web"
                >
                    <FontAwesomeIcon icon={['fas', 'globe']} />
                </a>
            )}
        </nav>
    )
}

export default SocialIcons
